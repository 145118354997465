<template>
  <div>
    <br />
    <div
      style="
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: row;
        flex-wrap: nowrap;
      "
    >
      <div v-if="true" style="width: 300px; margin-right: 30px">
        Отдел:
        <select class="form-select" id="class" v-model="sampleDoc.level">
          <option>Питание</option>
          <option>Секретарь</option>
          <option>Учебный отдел</option>
          <option>Воспитательный отдел</option>
        </select>
        Название шаблона:
        <input
          type="text"
          class="form-control"
          v-model="sampleDoc.name"
          required
        />
        Дата создания:
        <input
          type="date"
          id="Date"
          class="form-control"
          v-model="sampleDoc.date"
          required
        />
        Направление:
        <input
          type="text"
          class="form-control"
          v-model="sampleDoc.direction"
          required
        />
        Комментарий:
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          v-model="sampleDoc.comment"
          required
        ></textarea>
        Файл:
        <input
          type="file"
          id="file"
          ref="file"
          class="form-control"
          name=""
          v-on:change="handleFileUpload()"
        />

        <button
          @click="createSampleDoc()"
          id="createReciept"
          class="btn btn-success"
          style="
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;
          "
        >
          Создать шаблон
        </button>
      </div>
      <div>
        <div>
          <div
            style="width: 700px"
            v-for="level in listDocuments"
            :key="level._id"
          >
            <details>
              <summary>{{ level.level }}</summary>

              <div>
                <table class="reciept-table" style="margin-top: 5px">
                  <tr>
                    <th>Название документа</th>
                    <th>Имя файла</th>
                    <th>Дата</th>
                    <th>Направление</th>
                    <th>Действия</th>
                  </tr>
                  <tr v-for="doc in level.documents" :key="doc.name">
                    <td>{{ doc.name }}</td>
                    <td>{{ doc.direction }}</td>
                    <td>{{ doc.src }}</td>
                    <td>{{ doc.fileName }}</td>

                    <td>
                      <button class="btn btn-outline-success">Скачать</button
                      ><button class="btn btn-outline-danger">Удалить</button>
                    </td>
                  </tr>
                </table>
              </div>
            </details>
          </div>
        </div>
      </div>
    </div>
    {{ sampleDoc }}
    <div v-if="sampleDoc.file">{{ sampleDoc.file.name }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sampleDoc: { level: "", date: new Date().toDateString().split(0, 10) },
      listDocuments: [
        {
          _id: "1",
          level: "Питание",
          documents: [
            {
              name: "Название документа",
              src: "ссылка",
              direction: "Направление",
              fileName: "Имя файла",
            },
            {
              name: "Название документа",
              src: "ссылка",
              direction: "Направление",
              fileName: "Имя файла",
            },
          ],
        },
        {
          _id: "2",
          level: "Учебный отдел",
          documents: [
            {
              name: "Название документа1",
              direction: "Направление1Ex jnltk",
              src: "ссылка1",
              fileName: "Имя файла1",
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleFileUpload() {
      //  this.sampleDoc.file = this.$refs.file.files[0];
      this.$set(this.sampleDoc, "file", this.$refs.file.files[0]);
    },
  },
  mounted() {
    this.sampleDoc.date = new Date();
  },
};
</script>

<style scoped>
/* details {
  width: 70%;
  margin: 0 auto;
  background: #ffffff;
  margin-bottom: 0.5rem;
  box-shadow: 0 0.1rem 1rem -0.5rem rgba(255, 255, 255, 0.971);
  border-radius: 5px;
  overflow: hidden;
}

summary {
  padding: 1rem;
  display: block;
  background: rgb(227, 227, 227);
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

summary:before {
  content: "";
  border-width: 0.4rem;
  border-style: solid;
  border-color: transparent transparent transparent rgb(0, 0, 0);
  position: absolute;
  top: 1.3rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.25s transform ease;
}

details[open] > summary:before {
  transform: rotate(90deg);
}

details summary::-webkit-details-marker {
  display: none;
}

details > ul {
  padding-bottom: 1rem;
  margin-bottom: 0;
}

@keyframes details-show {
  from {
    opacity: 0;
    transform: var(--details-translate, translateY(-0.5em));
  }
}

details[open] > *:not(summary) {
  animation: details-show 150ms ease-in-out;
}
 */

:root {
  --accordion-min-height: 56px;
  --accordion-max-height: 300px;
}

details {
  width: 800px;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  box-shadow: 0 0.1rem 1rem -0.5rem rgba(0, 0, 0, 0.4);

  border-radius: 4px;
  overflow: hidden;
  animation-name: accordion-close;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  transform-origin: top;
}

details[open] {
  animation-name: accordion-master;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  transform-origin: top;
}

@keyframes accordion-master {
  from {
    max-height: var(--accordion-min-height);
  }
  to {
    max-height: var(--accordion-max-height);
  }
}

@keyframes accordion-close {
  from {
    min-height: var(--accordion-max-height);
  }
  to {
    min-height: var(--accordion-min-height);
  }
}

summary {
  padding: 1rem;
  display: block;
  background-color: rgb(249, 249, 249);
  position: relative;
  cursor: pointer;
}

summary:before {
  content: "";
  background-image: url(https://raw.githubusercontent.com/microsoft/fluentui-system-icons/master/assets/Chevron%20Right/SVG/ic_fluent_chevron_right_48_filled.svg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  height: 24px;
  width: 24px;
  top: 1rem;
  right: 1rem;
  transform: rotate(90deg);
  transition: 0.25s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
details[open] > summary:before {
  transform: rotate(270deg);
}

details summary::-webkit-details-marker {
  display: none;
}

details > div {
  padding-bottom: 1rem;
  margin-bottom: 0;
  opacity: 0;
  transition: 0.3s;
  height: 0;
  background: #ffffff;
  margin-top: 0;
  padding-top: 1rem;
  animation-name: accordion-content-close;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

details[open] > div {
  opacity: 1;
  height: auto;
  animation-name: accordion;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  transform-origin: top;
}

@keyframes accordion {
  from {
    transform: scaleY(0);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes accordion-content-close {
  from {
    margin-top: 0;
  }
  to {
    margin-top: -300px;
  }
}

@keyframes accordion-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
